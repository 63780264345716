import * as React from "react"
import "twin.macro"

// for now this quiz component is competitions-specific. However it should be reasonably easy to generalize.

type CompCategory =
  | "Prejudged"
  | "Indiv Oral"
  | "Objective"
  | "Impromptu"
  | "Tech"

type QuestionOption = {
  text: string
  partOf: CompCategory[]
}

type Question = {
  text: string
  options: QuestionOption[]
}

const categories: CompCategory[] = [
  "Prejudged",
  "Indiv Oral",
  "Objective",
  "Impromptu",
  "Tech",
]
const team: CompCategory[] = ["Prejudged", "Impromptu", "Tech"]
const indiv: CompCategory[] = ["Indiv Oral", "Objective"]
const speaking: CompCategory[] = [
  "Indiv Oral",
  "Impromptu",
  "Tech",
  "Prejudged",
]

const questions: Question[] = [
  {
    text: "In a group setting, I:",
    options: [
      {
        text: "Am the facilitator and will keep the group on track",
        partOf: [...team],
      },
      {
        text: "Do my part, nothing more or less",
        partOf: [],
      },
      {
        text: "Prefer to work alone",
        partOf: [...indiv],
      },
    ],
  },
  {
    text: "What motivates you?",
    options: [
      {
        text: "I motivate myself",
        partOf: [...indiv],
      },
      {
        text: "I get motivated by others",
        partOf: [...team],
      },
    ],
  },
  {
    text: "Are you interested in public speaking?",
    options: [
      {
        text: "No",
        partOf: ["Objective"],
      },
      {
        text: "Yes",
        partOf: [...speaking],
      },
    ],
  },
  {
    text:
      "Are you interested in competing in a technology related competition?",
    options: [
      {
        text: "No",
        partOf: categories.filter(x => x !== "Tech"),
      },
      {
        text: "Yes",
        partOf: ["Tech"],
      },
    ],
  },
  {
    text: "Are you interested in graphic design?",
    options: [
      {
        text: "No",
        partOf: categories.filter(x => x !== "Tech"),
      },
      {
        text: "Yes",
        partOf: ["Tech"],
      },
    ],
  },
  {
    text: "Are you a strong test taker?",
    options: [
      {
        text: "No",
        partOf: ["Prejudged", "Indiv Oral", "Tech"],
      },
      {
        text: "Yes",
        partOf: ["Objective", "Impromptu"],
      },
    ],
  },
  {
    text:
      "If you realized that you had to give a presentation in class the next day, you would:",
    options: [
      {
        text: "Wing It",
        partOf: ["Impromptu", "Indiv Oral"],
      },
      {
        text: "Write out your speech on flashcards",
        partOf: ["Prejudged", "Indiv Oral", "Tech"],
      },
      {
        text: "Choose to take a test on the subject instead",
        partOf: ["Objective"],
      },
    ],
  },
  {
    text: "You have a really busy incoming week, do you:",
    options: [
      {
        text: "Make a plan for the week so you don’t forget anything.",
        partOf: ["Objective", "Impromptu"],
      },
      {
        text: "Squeeze all your homework into one day",
        partOf: [],
      },
      {
        text: "Forget half of the things I need to do",
        partOf: [],
      },
      {
        text:
          "I wouldn’t have an especially busy week because I would have planned ahead and distributed my activities",
        partOf: ["Prejudged", "Indiv Oral"],
      },
    ],
  },
  {
    text: "Do you enjoy researching?",
    options: [
      {
        text: "No",
        partOf: categories.filter(x => x !== "Prejudged"),
      },
      {
        text: "Yes",
        partOf: ["Prejudged"],
      },
    ],
  },
  {
    text: "How comfortable are you with reaching out for help?",
    options: [
      {
        text: "Super Comfortable",
        partOf: indiv,
      },
      {
        text: "I’ll ask if I’m really confused",
        partOf: team,
      },
      {
        text: "I’d rather keep it to myself",
        partOf: [],
      },
    ],
  },
  {
    text: "How much time are you willing to dedicate to FBLA per week?",
    options: [
      {
        text: "4 hours",
        partOf: ["Prejudged", "Impromptu"],
      },
      {
        text: "2 hours",
        partOf: ["Tech", "Indiv Oral"],
      },
      {
        text: "1 hour",
        partOf: ["Objective"],
      },
    ],
  },
]

export default function Quiz() {
  const [selection, setSelection] = React.useState({})
  const [results, setResults] = React.useState(null)
  const handleQuizCompletion = () => {
    let total = categories.reduce((acc, cur) => {
      acc[cur] = 0
      for (let question of questions) {
        for (let option of question.options) {
          if (option.partOf.includes(cur)) acc[cur]++
        }
      }
      return acc
    }, {})
    let userSelected = categories.reduce((acc, cur) => {
      acc[cur] = 0
      for (let question of questions) {
        for (let i = 0; i < question.options.length; i++) {
          let option = question.options[i]
          if (option.partOf.includes(cur) && selection[question.text] === i)
            acc[cur]++
        }
      }
      return acc
    }, {})
    setResults(
      categories.reduce((acc, cur) => {
        acc[cur] = userSelected[cur] / total[cur]
        return acc
      }, {})
    )
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  return (
    <>
      {results !== null && (
        <div tw="mb-12">
          <h2 tw="text-3xl font-bold mb-4">Your Results!</h2>
          {categories.map(category => (
            <div tw="mb-2 text-gray-700 text-xl">
              {category}:{" "}
              <b tw="font-bold">{Math.round(results[category] * 100)}%</b>
            </div>
          ))}
        </div>
      )}
      <div tw="space-y-8">
        {questions.map((question, idx) => (
          <div key={question.text}>
            <p tw="text-xl">
              <span tw="font-bold">Q{idx + 1}</span>. {question.text}
            </p>

            <div tw="mt-4 space-y-1 ml-8">
              {question.options.map((option, idx) => (
                <div>
                  <div tw="flex items-center" key={option.text}>
                    <input
                      id={question.text + option.text}
                      name={question.text}
                      type="radio"
                      checked={selection[question.text] === idx}
                      onChange={e => {
                        if (results !== null) return
                        if (e.target.value) {
                          setSelection({
                            ...selection,
                            [question.text]: idx,
                          })
                        }
                      }}
                      tw="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <label htmlFor={question.text + option.text} tw="ml-3 py-1">
                      <span tw="block text-base text-gray-700">
                        {option.text}
                      </span>
                    </label>
                  </div>
                  {results !== null && option.partOf.length > 0 && (
                    <p tw="ml-7 text-gray-400 pb-1">
                      Part of: {option.partOf.join(", ")}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {results === null ? (
        <div tw="text-center">
          <button
            tw="rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 font-bold shadow transition duration-300 bg-blue-600 text-white hocus:bg-blue-700 hocus:text-blue-100 focus:outline-none focus:shadow-outline"
            onClick={() => handleQuizCompletion()}
          >
            Submit!
          </button>
        </div>
      ) : (
        <div tw="text-center">
          <button
            tw="rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 font-bold shadow transition duration-300 bg-blue-600 text-white hocus:bg-blue-700 hocus:text-blue-100 focus:outline-none focus:shadow-outline"
            onClick={() => {
              setSelection({})
              setResults(null)
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }}
          >
            Try Again
          </button>
        </div>
      )}
    </>
  )
}
