import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "twin.macro"
import { Link } from "gatsby"
import Quiz from "../components/competitions/Quiz"

const CompetitionsQuizPage = () => (
  <Layout>
    <SEO title="Competitions" />

    <div tw="max-w-3xl mx-auto mb-20">
      <div tw="px-4 sm:px-6 md:px-8">
        <div tw="my-12 md:my-20">
          <h1 tw="text-center text-3xl sm:text-4xl md:text-5xl font-black">
            Competitions Quiz
          </h1>
          <p tw="text-center sm:text-lg text-gray-600 mt-4">
            Want to learn more about FBLA Competitions? Visit the{" "}
            <Link to="/competitions" tw="underline text-blue-600">
              Competitions
            </Link>{" "}
            page!
          </p>
        </div>
        <Quiz />
      </div>
    </div>
  </Layout>
)

export default CompetitionsQuizPage
